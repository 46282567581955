import { styled } from '@mui/material';

export const FlexCenter = styled('div')`
  display: flex;
  align-items: center;
`;

export const CodeWrapper = styled('pre')`
  white-space: pre-wrap;
`;

export const StyledCountryEmojiWrapper = styled('span')`
  font-size: 0.8rem;
`;

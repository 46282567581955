import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Environment,
  EntityPromotionsResponse,
  EnvironmentPromotionsResponse,
  Promotion,
  PromotionResponse,
  SpacePromotionResponse,
} from '@playq/octopus-common';
import { CommonSuccess } from '@playq/services-shared';

import { appToolkit, activeSpaceToolkit } from '/store';
import { snackbarService } from '/common/snackbarService';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';
import { getSandboxPromotion } from '/shared/Promotions/helpers';
import { useEitherMutationScripted } from '/api/hooks/scriptsService/useEitherMutationScripted';
import { IInvokeScriptsHookParams, IScriptHookName } from '/common/scripts/types';
import { IDemoteEntityWithEnv, IDemoteEntityWithoutEnv, IPromoteEntityWithEnv, IPromoteEntityWithoutEnv } from '/api';
import { IMutationServiceMethod } from '/api/hooks/scriptsService/types';

import { IPromotionHandlingParams } from '../EnvironmentForm/types';

import { EntityID, IPromotionsServiceProps } from './types';
import { getEntityLabels, getLiveState, getSandboxState } from './helpers';

export const Service = <ID extends EntityID, ENV extends boolean = true>({
  promotions,
  version,
  component: Component,
  entityID,
  spaceless,
  manifestName,
  notificationLabel,
  service,
  canWrite = true,
  canWriteLive = true,
  disabled = false,
  promotionResolverData,
  onChange,
  ...props
}: IPromotionsServiceProps<ID, ENV>) => {
  const app = useSelector(appToolkit.selectors.app);
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const space = spaceless ? undefined : activeSpace;
  const {
    resolver: promotionResolver,
    promotionHookName: promotionResolverHookName,
    demotionHookName: demotionResolverHookName,
  } = promotionResolverData ?? {};

  const [envLoading, setEnvLoading] = useState<Environment | null | undefined>(null);
  const singleEnvLastPromotionBeenMadeToRef = useRef<Environment | null>(null);

  const { mutate: promoteEntity } = useEitherMutationScripted<
    Parameters<typeof service.promote>,
    Promotion,
    typeof promotionResolver
  >({
    serviceMethod: service.promote.bind(service) as IMutationServiceMethod<
      Parameters<IPromoteEntityWithEnv<ID> | IPromoteEntityWithoutEnv<ID>>,
      Promotion
    >,
    scriptsResolver: promotionResolver,
    snackbarMessageMetadata: {
      serviceName: service.getClassName(),
      mutationType: 'promotion',
    },
    options: {
      onMutate: ({ serviceMethodArgs: [, , env] }) => {
        setEnvLoading(env);
      },
      onSuccess: (res: Promotion, { serviceMethodArgs: [, , env] }) => {
        setEnvLoading(null);

        const entityName = notificationLabel || getEntityLabels(entityID)?.notification;

        if (entityName) {
          snackbarService.success(`${entityName} scheduled to promote${env ? ` on ${env}` : ''}`);
        }

        if (env || singleEnvLastPromotionBeenMadeToRef.current) {
          onChange(addPromotionToPromotions((env ?? singleEnvLastPromotionBeenMadeToRef.current) as Environment, res));
        }
      },
      onError: (err) => {
        setEnvLoading(null);
        console.error(err);
        snackbarService.genericFailure(err);
      },
    },
  });

  const { mutate: demoteEntity } = useEitherMutationScripted<
    Parameters<typeof service.demote>,
    CommonSuccess,
    typeof promotionResolver
  >({
    serviceMethod: service.demote.bind(service) as IMutationServiceMethod<
      Parameters<IDemoteEntityWithEnv<ID> | IDemoteEntityWithoutEnv<ID>>,
      CommonSuccess
    >,
    scriptsResolver: promotionResolver,
    snackbarMessageMetadata: {
      serviceName: service.getClassName(),
      mutationType: 'demotion',
    },
    options: {
      onMutate: ({ serviceMethodArgs: [, , env] }) => {
        setEnvLoading(env);
      },
      onSuccess: (_res, { serviceMethodArgs: [, , env] }) => {
        setEnvLoading(null);
        const entityName = notificationLabel || getEntityLabels(entityID)?.notification;
        if (entityName) {
          snackbarService.success(`${entityName} has been demoted${env ? ` from ${env}` : ''}`);
        }
        onChange(removePromotionFromPromotions(env));
      },
      onError: (err) => {
        setEnvLoading(null);
        console.error(err);
        snackbarService.genericFailure(err);
      },
    },
  });

  const getDefaultSandboxPromotion = (): EnvironmentPromotionsResponse => {
    const promotion = new EnvironmentPromotionsResponse();
    promotion.default_ = undefined;
    promotion.custom = [];
    return promotion;
  };

  const addPromotionToPromotions = (env: Environment, promotion: Promotion): EntityPromotionsResponse => {
    const promotionResponse = new PromotionResponse();
    promotionResponse.id = promotion.id;
    promotionResponse.author = promotion.author;
    promotionResponse.state = promotion.state;
    promotionResponse.version = promotion.version;
    promotionResponse.authorName = undefined;

    const newPromotions = new EntityPromotionsResponse();
    newPromotions.sandbox = promotions ? promotions.sandbox : getDefaultSandboxPromotion();
    newPromotions.live = promotions?.live;

    if (env === Environment.Live) {
      newPromotions.live = promotionResponse;
    } else if (!space) {
      newPromotions.sandbox.default_ = promotionResponse;
    } else {
      const spacePromotionResponse = new SpacePromotionResponse({
        id: space.id.serialize(),
        promotion: promotionResponse.serialize(),
        spaceName: space.name,
      });

      const spacePromotionIndex = newPromotions.sandbox.custom.findIndex(
        (p) => p.id.serialize() === space.id.serialize()
      );
      if (spacePromotionIndex !== -1) {
        newPromotions.sandbox.custom = newPromotions.sandbox.custom.map((_, index) =>
          index === spacePromotionIndex ? spacePromotionResponse : _
        );
        newPromotions.sandbox.custom = newPromotions.sandbox.custom.filter((spacePromotion, index) =>
          spacePromotion.id.serialize() === space.id.serialize() ? index === spacePromotionIndex : true
        );
      } else {
        newPromotions.sandbox.custom.push(spacePromotionResponse);
      }
    }
    return newPromotions;
  };

  const removePromotionFromPromotions = (env?: Environment): EntityPromotionsResponse => {
    const newPromotions = new EntityPromotionsResponse();

    newPromotions.sandbox = promotions?.sandbox || getDefaultSandboxPromotion();
    newPromotions.live = promotions?.live;

    if (env === Environment.Sandbox) {
      if (space) {
        newPromotions.sandbox.custom = newPromotions.sandbox.custom.filter(
          (spacePromotion) => spacePromotion.id.serialize() !== space.id.serialize()
        );
      } else {
        newPromotions.sandbox.default_ = undefined;
      }
    } else {
      newPromotions.live = undefined;
    }

    return newPromotions;
  };

  const sandboxState = useMemo(() => {
    const state = getSandboxState(entityID, manifestName, promotions, version, app, space);

    if (envLoading === Environment.Sandbox) {
      state.loading = true;
      state.status = 'In Progress';
      state.disablePromote = true;
      state.disableDemote = true;
    } else if (envLoading === Environment.Live) {
      state.disablePromote = true;
      state.disableDemote = true;
    }

    if (disabled || !canWrite) {
      state.disablePromote = true;
      state.disableDemote = true;
    }

    return state;
  }, [entityID, manifestName, promotions, version, app, space, disabled, canWrite, envLoading]);

  const liveState = useMemo(() => {
    const state = getLiveState(entityID, manifestName, promotions, version, app, space);

    if (envLoading === Environment.Live) {
      state.loading = true;
      state.status = 'In Progress';
      state.disablePromote = true;
      state.disableDemote = true;
    } else if (envLoading === Environment.Sandbox) {
      state.disablePromote = true;
      state.disableDemote = true;
    }

    if (disabled || !canWriteLive) {
      state.disablePromote = true;
      state.disableDemote = true;
    }

    return state;
  }, [entityID, manifestName, promotions, version, app, space, disabled, canWriteLive, envLoading]);

  const getNextLiveVersion = (): number | undefined => {
    const sandboxPromotion = getSandboxPromotion(promotions, space);

    if (sandboxPromotion) {
      return sandboxPromotion.version;
    }

    if (version !== undefined) {
      return version;
    }
  };

  const promoteEnv = ({ env, singleEnvToUse }: IPromotionHandlingParams) => {
    if (version === undefined || version <= 0) {
      return;
    }

    const shouldUseSingleEnv: boolean = env === undefined;

    if (shouldUseSingleEnv) {
      promoteEntity({
        serviceMethodArgs: [entityID, version] as Parameters<typeof service.promote>,
        scriptsResolverArgs: promotionResolverData && [
          promotionResolverHookName as IScriptHookName,
          singleEnvToUse as Environment,
          version,
        ],
      });

      singleEnvLastPromotionBeenMadeToRef.current = singleEnvToUse as Environment;

      return;
    }

    const sandboxPromotion = getSandboxPromotion(promotions, space);
    const promotionVersion = env === Environment.Live && sandboxPromotion ? sandboxPromotion.version : version;
    const toSpace = env === Environment.Sandbox ? space?.id : undefined;

    promoteEntity({
      serviceMethodArgs: [entityID, promotionVersion, env, toSpace] as unknown as Parameters<typeof service.promote>,
      scriptsResolverArgs: promotionResolverData && [
        promotionResolverHookName as IScriptHookName,
        env as Environment,
        promotionVersion,
      ],
    });

    singleEnvLastPromotionBeenMadeToRef.current = null;
  };

  const handlePromote = ({ env, singleEnvToUse }: IPromotionHandlingParams) => {
    const updatedEnv = singleEnvToUse ?? env;
    if (updatedEnv === undefined) {
      return;
    }

    const updatedVersion = updatedEnv === Environment.Live ? getNextLiveVersion() : version;
    const updatedVersionLabel = updatedVersion !== undefined ? 'v' + String(updatedVersion) : 'the entity';

    confirmDialog({
      title: `WARNING`,
      text: `Are you sure to promote ${updatedVersionLabel} to ${updatedEnv.toLowerCase()} environment?`,
      type: ConfirmDialogType.Warning,
      closeButton: { label: 'Cancel' },
      successButton: { label: 'Ok' },
      onSuccess: () => {
        if (singleEnvToUse) {
          promoteEnv({ singleEnvToUse });
        } else promoteEnv({ env });
      },
    });
  };

  const handleDemote = ({ env }: IPromotionHandlingParams) => {
    const scriptsResolverArgs = promotionResolverData && [demotionResolverHookName as IScriptHookName, env];

    const sandboxPromotion = getSandboxPromotion(promotions, space);
    const promotion = env === Environment.Sandbox ? sandboxPromotion : promotions?.live;
    const toSpace = env === Environment.Sandbox ? space?.id : undefined;
    const text =
      promotion?.version !== undefined && env
        ? `Are you sure to demote v${promotion.version} from ${env.toLowerCase()} environment?`
        : 'Are you sure to demote the entity?';

    confirmDialog({
      title: `WARNING`,
      text,
      type: ConfirmDialogType.Warning,
      closeButton: { label: 'Cancel' },
      successButton: { label: 'Ok' },
      onSuccess: () => {
        if (env === undefined) {
          demoteEntity({
            serviceMethodArgs: [entityID] as Parameters<typeof service.demote>,
            scriptsResolverArgs: scriptsResolverArgs as IInvokeScriptsHookParams | undefined,
          });
        } else if (promotion) {
          demoteEntity({
            serviceMethodArgs: [entityID, promotion.version, env, toSpace] as unknown as Parameters<
              typeof service.demote
            >,
            scriptsResolverArgs: scriptsResolverArgs as IInvokeScriptsHookParams | undefined,
          });
        }
      },
    });
  };

  return (
    <Component
      promotions={promotions}
      version={version}
      spaceless={spaceless}
      {...props}
      onPromote={handlePromote}
      onDemote={handleDemote}
      sandboxState={sandboxState}
      liveState={liveState}
    />
  );
};

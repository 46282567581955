import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Environment } from '@playq/octopus-common';

import { activeSpaceToolkit } from '/store/toolkits';
import { EnvironmentForm } from '/shared/Promotions/components';
import { IPromotionsComponentProps } from '/shared/Promotions/components/Service/types';
import { IPromotionHandlingParams } from '/shared/Promotions/components/EnvironmentForm/types';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';

import { Wrapper } from './styles';

export const Row: FC<IPromotionsComponentProps> = ({
  promotions,
  sandboxState,
  liveState,
  isPromotedOnLive = false,
  spaceless,
  title,
  className,
  singleEnvToUse: singleEnvToUseProvided,
  onPromote,
  onDemote,
  setIsPromotedOnLive,
}) => {
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const space = spaceless ? undefined : activeSpace;

  const shouldShowSandboxControls: boolean = !singleEnvToUseProvided || singleEnvToUseProvided === Environment.Sandbox;

  const shouldShowLiveControls: boolean = !singleEnvToUseProvided || singleEnvToUseProvided === Environment.Live;

  useEffect(() => {
    if (setIsPromotedOnLive) {
      setIsPromotedOnLive(isPromotedOnLive);
    }
  }, [isPromotedOnLive, setIsPromotedOnLive]);

  const handlePromotion = (params: IPromotionHandlingParams) => {
    const { env, singleEnvToUse } = params;
    const targetEnv: Environment = env ?? singleEnvToUse;

    confirmDialog({
      title: `WARNING`,
      text: `Are you sure to promote selected items to ${targetEnv.toLowerCase()} environment?`,
      type: ConfirmDialogType.Warning,
      closeButton: { label: 'Cancel' },
      successButton: { label: 'Ok' },
      onSuccess: () => {
        onPromote(params);
      },
    });

    if (targetEnv === Environment.Live && setIsPromotedOnLive) {
      setIsPromotedOnLive(true);
    }
  };

  const handleDemotion = (params: IPromotionHandlingParams) => {
    const { env, singleEnvToUse } = params;
    const targetEnv: Environment = env ?? singleEnvToUse;

    confirmDialog({
      title: `WARNING`,
      text: `Are you sure to demote selected items from ${targetEnv.toLowerCase()} environment?`,
      type: ConfirmDialogType.Warning,
      closeButton: { label: 'Cancel' },
      successButton: { label: 'Ok' },
      onSuccess: () => {
        onDemote(params);
      },
    });

    if (targetEnv === Environment.Live && setIsPromotedOnLive) {
      setIsPromotedOnLive(false);
    }
  };

  return (
    <Wrapper className={className}>
      {title}
      {shouldShowSandboxControls && (
        <EnvironmentForm
          env={Environment.Sandbox}
          promotions={promotions}
          onPromote={handlePromotion}
          onDemote={handleDemotion}
          space={space}
          version={sandboxState.version}
          loading={sandboxState.loading}
          status={sandboxState.status}
          disablePromote={sandboxState.disablePromote}
          disableDemote={sandboxState.disableDemote}
          singleEnvToUse={singleEnvToUseProvided}
        />
      )}
      {shouldShowLiveControls && (
        <EnvironmentForm
          env={Environment.Live}
          promotions={promotions}
          onPromote={handlePromotion}
          onDemote={handleDemotion}
          version={liveState.version}
          loading={liveState.loading}
          status={liveState.status}
          disablePromote={liveState.disablePromote}
          disableDemote={liveState.disableDemote}
          singleEnvToUse={singleEnvToUseProvided}
        />
      )}
    </Wrapper>
  );
};

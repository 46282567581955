import { FC, useMemo } from 'react';
import { byCountry as getCountryObjByName } from 'country-code-lookup';

import { UserLogin } from '@playq/octopus-notifications';

import { FlexCenter, StyledCountryEmojiWrapper } from './styles';
import { getEmojiFromCountryCode } from './helpers';

export type IUserNotificationViewProps = {
  notification: UserLogin;
  inline?: boolean;
};

const UserNotificationView: FC<IUserNotificationViewProps> = (props) => {
  const { notification, inline }: IUserNotificationViewProps = props;

  const countryEmoji = useMemo<string>(() => {
    const countryISOCode: string | undefined = getCountryObjByName(notification.country)?.iso2;

    return !countryISOCode ? '' : getEmojiFromCountryCode(countryISOCode);
  }, [notification]);

  if (inline) {
    return <span>IP: {notification.ip}</span>;
  }

  return (
    <>
      <div>IP: {notification.ip}</div>

      {notification.country !== '' && (
        <FlexCenter>
          <span>Country:&nbsp;</span>
          <StyledCountryEmojiWrapper>{countryEmoji}</StyledCountryEmojiWrapper>
          <span>&nbsp;{notification.country}</span>
        </FlexCenter>
      )}
      {notification.city !== '' && <div>City: {notification.city}</div>}

      <div>At: {notification.at.toString()}</div>
      <div>Login with: {notification.method}</div>

      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {notification.userID && <div>User ID: {notification.userID.toString()}</div>}
      {notification.userName && <div>Full Name: {notification.userName}</div>}
    </>
  );
};

export default UserNotificationView;

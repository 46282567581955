import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { PhoneIphone as MobileIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { DeviceID, UnknownDevice, KnownDevice, Device } from '@playq/octopus2-devices';

import { onlineDevicesManagement, services2, unwrapEither } from '/api';
import { useLocalStorage, useModal } from '/hooks';
import { deviceToolkit, globalDispatch } from '/store';
import { buzzerOnlineDevicesNotifications$ } from '/common/buzzer/buzzerNotifications';
import { SelectDialog } from '/shared/SelectDialog';
import { snackbarService } from '/common/snackbarService';
import { BuzzerOnlineDevicesNotifications } from '/common/buzzer';

import { BadgeStyled, ButtonStyled } from './styles';
import { checkNameInDevice } from './helpers';
import { ButtonGroup } from './components';
import { DeviceTable } from './DeviceTable';
import { LoadingTextProgress } from './ProgressText';
import { StorageDevice } from './types';

export const OnlineDevices: FC = memo(() => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const subscribtions = useSelector(deviceToolkit.selectors.devices);
  const subscriptionDevice = useSelector(deviceToolkit.selectors.subscribtionDevice);
  const [loadingConnection, setLoadingConnection] = useState(false);
  const { open, close, isOpen } = useModal();
  const { updateConnectWithDevice, setStreamEvent, subscribeDevice, unsubscribeDevice } = globalDispatch(dispatch);
  const [storedDevice, setStoredDevice, removeItem] = useLocalStorage<StorageDevice | null>(
    'DeviceID',
    null,
    handleDeviceSubscription
  );

  function handleDeviceSubscription(updatedData: StorageDevice | null) {
    if (updatedData) {
      subscribeDevice(checkNameInDevice(updatedData.device));
    } else {
      unsubscribeDevice(subscribtions[0].id);
    }
  }

  const location = useLocation();
  const hasNotSubscribtions = subscribtions.length === 0;
  const refreshDevice = useCallback(() => {
    return queryClient.invalidateQueries(onlineDevicesManagement);
  }, [queryClient]);
  const handleDeviceNotification = useCallback(
    (notification: BuzzerOnlineDevicesNotifications) => {
      if (notification instanceof KnownDevice || notification instanceof UnknownDevice) {
        refreshDevice();
        updateConnectWithDevice(notification);
        if (notification.connection !== undefined) {
          if (location.pathname.includes('device')) {
            snackbarService.success('Device online');
          }
        }
      } else {
        setStreamEvent(notification);
      }
    },
    [location.pathname, refreshDevice, setStreamEvent, updateConnectWithDevice]
  );

  const subscribe = (
    deviceID: string,
    device: Device,
    reloadPage = false,
    changeConnectionStatus?: (val: boolean) => void
  ) => {
    unwrapEither(services2.onlineDevicesEphemeral.sendSubscription(new DeviceID(deviceID)))
      .then((res) => {
        const status = { ...device.serialize(), connection: res.connection?.serialize() };
        setStoredDevice({ deviceID: deviceID, device: status });
        if (changeConnectionStatus) {
          changeConnectionStatus(false);
        }
        if (!reloadPage) {
          close();
        }
      })
      .catch((err) => {
        console.error(err);
        if (changeConnectionStatus) {
          changeConnectionStatus(false);
        }
      });
  };

  const changeSubscription = (currentId: string, pickedDevice: Device) => {
    if (currentId !== pickedDevice.id.serialize()) {
      unwrapEither(services2.onlineDevicesEphemeral.sendUnsubscription(new DeviceID(currentId))).then(() => {
        subscribe(pickedDevice.id.serialize(), pickedDevice, true);
      });
    }
  };
  const unsubscribe = (reloadPage = true) => {
    if (hasNotSubscribtions) return;

    unwrapEither(services2.onlineDevicesEphemeral.sendUnsubscription(new DeviceID(subscribtions[0].id))).then(() => {
      removeItem();
      if (reloadPage) {
        close();
      }
    });
  };
  useEffect(() => {
    const deviceNotifications = buzzerOnlineDevicesNotifications$.subscribe(handleDeviceNotification);
    if (storedDevice !== null && !subscriptionDevice?.device) {
      setLoadingConnection(true);
      setTimeout(() => {
        subscribe(storedDevice.deviceID, checkNameInDevice(storedDevice.device), true, setLoadingConnection);
      }, 2000);
    }
    return () => {
      deviceNotifications.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeviceDialog = () => {
    open();
  };
  return (
    <>
      <Tooltip title='View devices' placement='bottom'>
        <ButtonStyled onClick={openDeviceDialog} color='inherit'>
          <BadgeStyled
            $offline={subscriptionDevice?.device.connection === undefined}
            invisible={subscriptionDevice?.device === undefined}
            variant='dot'
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
          >
            <MobileIcon />
          </BadgeStyled>
          {loadingConnection ? <LoadingTextProgress /> : subscriptionDevice?.name}
        </ButtonStyled>
      </Tooltip>
      {isOpen && (
        <SelectDialog
          title='Devices'
          open={isOpen}
          onClose={close}
          actions={
            <ButtonGroup
              tooltipText={`${hasNotSubscribtions ? 'You are not subscribed' : ''}`}
              refetch={refreshDevice}
              unsubscribe={unsubscribe}
              disabled={hasNotSubscribtions}
            />
          }
          content={
            <DeviceTable
              subscribeDevice={subscribe}
              unsubscribe={unsubscribe}
              changeSubscription={changeSubscription}
            />
          }
        />
      )}
    </>
  );
});

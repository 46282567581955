import { FC, ReactNode, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  useEventCallback,
} from '@mui/material';
import { Error } from '@mui/icons-material';

import {
  Environment,
  PromotionComplete,
  PromotionDefunct,
  PromotionInProgress,
  PromotionScheduled,
  PromotionStalled,
  PromotionState,
} from '@playq/octopus-common';

import { formatDate } from '/helpers';
import { Service } from '/shared/Promotions/components/Service';
import { getSandboxPromotion } from '/shared/Promotions/helpers';
import { EnvLabel } from '/shared/Environment/EnvironmentStatus';
import { IThemeModeContext } from '/common/models';
import { ThemeModeContext } from '/common';
import { EntityID } from '/shared/Promotions/components/Service/types';
import { activeSpaceToolkit } from '/store';
import { EnvironmentForm } from '/shared/Promotions/components';
import { useSpaceQuery } from '/api';

import {
  EnvironmentsWrapper,
  ErrorText,
  InfoIcon,
  Label,
  liveColor,
  PreviewButton,
  sandboxColor,
  TextAnimation,
  Title,
  WarningIcon,
  Wrapper,
} from './styles';
import { IPreview, IPromotionsPreviewProps } from './types';

const getEnvStatusPreview = (state: PromotionState, env: Environment): ReactNode => {
  if (state instanceof PromotionInProgress) {
    return <TextAnimation env={env} text={`${(state.progress * 100).toFixed(2)} %`} />;
  }
  if (state instanceof PromotionScheduled) {
    return (
      <Label>
        <TextAnimation env={env} text='Scheduled' />
      </Label>
    );
  }
  if (state instanceof PromotionDefunct) {
    return (
      <Typography variant='inherit' color='error'>
        Defunct!
      </Typography>
    );
  }
  if (state instanceof PromotionStalled) {
    return (
      <Typography variant='inherit' color='textSecondary'>
        Stalled!
      </Typography>
    );
  }
  return (
    <Typography variant='inherit' color='textSecondary'>
      Not Promoted
    </Typography>
  );
};

const { Sandbox, Live } = Environment;

export const Preview: FC<IPreview> = ({
  promotions,
  lastAvailableVersion,
  title,
  sandboxState,
  spaceless,
  liveState,
  wasLive,
  filterBySpace,
  singleEnvToUse,
  previewButtonProps,
  open: propsOpen,
  onPromote,
  onDemote,
  afterModalOpen,
  onClose,
  version,
  info = null,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disableSandboxPromotion, setDisableSandboxPromotion] = useState(false);
  const [sandboxValidationProcessing, setSandboxValidationProcessing] = useState(false);
  const [sandboxErr, setSandboxErr] = useState('');
  const themeModeContext: IThemeModeContext = useContext(ThemeModeContext);

  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const currentSpace = useSpaceQuery(filterBySpace?.space)?.data;
  const space = useMemo(() => (spaceless ? undefined : activeSpace), [activeSpace, spaceless]);

  const shouldShowSandboxControls: boolean = !singleEnvToUse || singleEnvToUse === Environment.Sandbox;

  const shouldShowLiveControls: boolean = !singleEnvToUse || singleEnvToUse === Environment.Live;

  const handleOpenDialog = useEventCallback(() => {
    setDialogOpen(true);
    setDisableSandboxPromotion(false);
    setSandboxErr('');
    if (afterModalOpen) {
      setSandboxValidationProcessing(true);
      setDisableSandboxPromotion(true);
      afterModalOpen((err) => {
        setSandboxValidationProcessing(false);
        if (err) {
          setSandboxErr(err);
          return;
        }
        setDisableSandboxPromotion(false);
      });
    }
  });

  useEffect(() => {
    if (propsOpen) {
      handleOpenDialog();
    }
  }, [propsOpen, handleOpenDialog]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    onClose?.();
  };

  const getLastVersion = () => {
    if (lastAvailableVersion !== undefined) {
      return version !== undefined ? Math.max(lastAvailableVersion, version) : lastAvailableVersion;
    }

    return version;
  };

  const getSandboxStatus = (): ReactNode => {
    const sandboxPromotion = getSandboxPromotion(promotions, space);

    if (sandboxPromotion) {
      const { state } = sandboxPromotion;

      if (state instanceof PromotionComplete) {
        return (
          <Label>
            <EnvLabel $themePalette={themeModeContext.getCurrentTheme().palette} env={Sandbox} clickable={true}>
              {Sandbox}
            </EnvLabel>
            {sandboxPromotion.version !== getLastVersion() && (
              <Tooltip
                title={
                  <div>
                    Promoted not the <b>latest</b> version! <br />
                    {sandboxPromotion.state instanceof PromotionComplete &&
                      `Promoted v${sandboxPromotion.version} at ${formatDate(sandboxPromotion.state.promotedAt)}`}
                  </div>
                }
                placement='top'
              >
                <WarningIcon htmlColor={sandboxColor} />
              </Tooltip>
            )}
          </Label>
        );
      }

      return <Label>{getEnvStatusPreview(sandboxPromotion.state, Sandbox)}</Label>;
    }
    return (
      <Label>
        <Typography variant='inherit' color='textSecondary'>
          <span>
            {Sandbox} {currentSpace ? ` (${currentSpace.name})` : ``}
          </span>
        </Typography>
      </Label>
    );
  };

  const getLiveStatus = (): ReactNode => {
    if (promotions?.live) {
      const { state } = promotions.live;

      if (state instanceof PromotionComplete) {
        const promotedVersion = promotions.live.version;
        const promotedNotTheLatest = promotedVersion !== getLastVersion();

        const showVersion = previewButtonProps?.withVersion && promotedNotTheLatest;

        return (
          <Label>
            <EnvLabel $themePalette={themeModeContext.getCurrentTheme().palette} env={Live} clickable={true}>
              {Live}
              {showVersion && ` v${promotedVersion}`}
            </EnvLabel>
            {promotedNotTheLatest && (
              <Tooltip
                title={
                  <div>
                    Promoted not the <b>latest</b> version! <br />
                  </div>
                }
                placement='top'
              >
                <WarningIcon htmlColor={liveColor} />
              </Tooltip>
            )}
          </Label>
        );
      }

      return <Label>{getEnvStatusPreview(promotions.live.state, Live)}</Label>;
    }

    return (
      <Label>
        <Typography variant='inherit' color='textSecondary'>
          Live
        </Typography>
        {wasLive && (
          <Tooltip title='Was Live' placement='top'>
            <InfoIcon fontSize='small' />
          </Tooltip>
        )}
      </Label>
    );
  };

  const handleStopPropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  const sandboxStatus = sandboxValidationProcessing ? 'Validating..' : sandboxState.status;

  return (
    <Wrapper onClick={handleStopPropagation}>
      <PreviewButton
        data-testid='button-status'
        $themePalette={themeModeContext.getCurrentTheme().palette}
        className={previewButtonProps?.className || ''}
        onClick={handleOpenDialog}
      >
        {previewButtonProps?.children ?? (
          <>
            {shouldShowSandboxControls && getSandboxStatus()}
            {shouldShowLiveControls && getLiveStatus()}
          </>
        )}
      </PreviewButton>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth='lg'>
        <DialogTitle>
          <Title>
            <Typography variant='h6'>{title ?? 'Promotions'}</Typography>
            {info}
          </Title>
        </DialogTitle>

        <DialogContent>
          <EnvironmentsWrapper>
            {shouldShowSandboxControls && (
              <EnvironmentForm
                env={Sandbox}
                promotions={promotions}
                onPromote={onPromote}
                onDemote={onDemote}
                space={space}
                version={sandboxState.version}
                loading={sandboxState.loading || sandboxValidationProcessing}
                status={sandboxStatus}
                disablePromote={sandboxState.disablePromote || disableSandboxPromotion}
                disableDemote={sandboxState.disableDemote}
                singleEnvToUse={singleEnvToUse}
              />
            )}
            {shouldShowLiveControls && (
              <EnvironmentForm
                env={Live}
                promotions={promotions}
                onPromote={onPromote}
                onDemote={onDemote}
                space={space}
                version={liveState.version}
                loading={liveState.loading}
                status={liveState.status}
                disablePromote={liveState.disablePromote}
                disableDemote={liveState.disableDemote}
                singleEnvToUse={singleEnvToUse}
              />
            )}
          </EnvironmentsWrapper>
        </DialogContent>

        <DialogActions>
          {sandboxErr && (
            <ErrorText>
              {sandboxErr} <Error fontSize='small' />
            </ErrorText>
          )}
          <Button onClick={handleCloseDialog} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

export const PromotionsPreview = <T extends EntityID>({ ...props }: IPromotionsPreviewProps<T>) => {
  return <Service<T> {...props} component={Preview} />;
};

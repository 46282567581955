import { FC, useMemo } from 'react';
import { Tooltip, Stack } from '@mui/material';
import { Block } from '@mui/icons-material';

import { Environment } from '@playq/octopus-common';

import { envColors } from '/constants';

import { Button } from '../Button';
import { Status } from '../Status';

import { PromotionForm, InfoIcon } from './styles';
import { IEnvironmentFormProps } from './types';

export const EnvironmentForm: FC<IEnvironmentFormProps> = ({
  env,
  promotions,
  onPromote,
  onDemote,
  space,
  version,
  loading = false,
  status,
  disablePromote = false,
  disableDemote = false,
  singleEnvToUse,
}) => {
  const handlePromote = () => {
    onPromote(singleEnvToUse ? { singleEnvToUse } : { env });
  };
  const handleDemote = () => {
    if (onDemote) {
      onDemote(singleEnvToUse ? { singleEnvToUse } : { env });
    }
  };
  const isSandbox = env === Environment.Sandbox;

  const sandboxSpaces = useMemo(() => {
    return isSandbox && promotions
      ? promotions.sandbox.custom.reduce<string[]>((acc, spacePromotion) => {
          if (!space || spacePromotion.id.serialize() !== space.id.serialize()) {
            return [...acc, spacePromotion.spaceName || spacePromotion.id.serialize()];
          }
          return acc;
        }, [])
      : [];
  }, [isSandbox, promotions, space]);

  const envColor = envColors[env];

  return (
    <PromotionForm mainColor={envColor} className='env-form'>
      <Button
        data-testid={`button-promote-${env.toLowerCase()}`}
        onClick={handlePromote}
        label='Push'
        version={version}
        disabled={!singleEnvToUse && disablePromote}
        className='promote'
      />
      <Status
        loading={loading}
        title={
          <Stack direction='row' alignItems='center'>
            {env}
            {space && isSandbox && (
              <span className='space'>
                Space: <span className='spaceName'>{space.name}</span>
              </span>
            )}
            {isSandbox && !!sandboxSpaces.length && (
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              <Tooltip title={`Promoted also at ${env || ''}: ${sandboxSpaces.join(', ')}`} placement='top'>
                <InfoIcon fontSize='small' />
              </Tooltip>
            )}
          </Stack>
        }
        text={status}
        className='status'
      />
      {onDemote && (
        <Button
          onClick={handleDemote}
          label={<Block fontSize='small' />}
          disabled={!singleEnvToUse && disableDemote}
          withArrow={false}
          data-testid={`button-demote-${env.toLowerCase()}`}
          className='demote'
        />
      )}
    </PromotionForm>
  );
};
